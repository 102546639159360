import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b4e7604a = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _a4d373c4 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _8a569224 = () => interopDefault(import('../pages/account/addresses.vue' /* webpackChunkName: "pages/account/addresses" */))
const _d5c979ba = () => interopDefault(import('../pages/account/favorites.vue' /* webpackChunkName: "pages/account/favorites" */))
const _fd715b3a = () => interopDefault(import('../pages/account/history/index.vue' /* webpackChunkName: "pages/account/history/index" */))
const _4a402adc = () => interopDefault(import('../pages/account/loyalty.vue' /* webpackChunkName: "pages/account/loyalty" */))
const _2ebde244 = () => interopDefault(import('../pages/account/preferences.vue' /* webpackChunkName: "pages/account/preferences" */))
const _cad57316 = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _244dcc87 = () => interopDefault(import('../pages/account/history/_historyItem.vue' /* webpackChunkName: "pages/account/history/_historyItem" */))
const _30c598e5 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _8ccc3f30 = () => interopDefault(import('../pages/confirm-registration.vue' /* webpackChunkName: "pages/confirm-registration" */))
const _e4cc5eaa = () => interopDefault(import('../pages/confirm-subscribe.vue' /* webpackChunkName: "pages/confirm-subscribe" */))
const _2162b877 = () => interopDefault(import('../pages/confirmation.vue' /* webpackChunkName: "pages/confirmation" */))
const _13941494 = () => interopDefault(import('../pages/delivery.vue' /* webpackChunkName: "pages/delivery" */))
const _998c04f6 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _5b2c1074 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _0b88c44c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _5e592ae7 = () => interopDefault(import('../pages/order-tracker.vue' /* webpackChunkName: "pages/order-tracker" */))
const _212bf5f4 = () => interopDefault(import('../pages/order-view.vue' /* webpackChunkName: "pages/order-view" */))
const _161b6334 = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _6399513e = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _01ef7dca = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _51b2bbaa = () => interopDefault(import('../pages/search-empty.vue' /* webpackChunkName: "pages/search-empty" */))
const _7e18cc75 = () => interopDefault(import('../pages/size-guide/index.vue' /* webpackChunkName: "pages/size-guide/index" */))
const _b4b32c62 = () => interopDefault(import('../pages/size-guide-old/index.vue' /* webpackChunkName: "pages/size-guide-old/index" */))
const _5d601c16 = () => interopDefault(import('../pages/storefinder.vue' /* webpackChunkName: "pages/storefinder" */))
const _20beaf76 = () => interopDefault(import('../pages/thank-you-page/index.vue' /* webpackChunkName: "pages/thank-you-page/index" */))
const _428935e0 = () => interopDefault(import('../pages/time-out.vue' /* webpackChunkName: "pages/time-out" */))
const _1187d258 = () => interopDefault(import('../pages/store/search.vue' /* webpackChunkName: "pages/store/search" */))
const _59770839 = () => interopDefault(import('../pages/size-guide-old/_sizeGuidePageId.vue' /* webpackChunkName: "pages/size-guide-old/_sizeGuidePageId" */))
const _2444a1d3 = () => interopDefault(import('../pages/size-guide/_sizeGuidePageId.vue' /* webpackChunkName: "pages/size-guide/_sizeGuidePageId" */))
const _9a0029fa = () => interopDefault(import('../pages/store/_index.vue' /* webpackChunkName: "pages/store/_index" */))
const _00747013 = () => interopDefault(import('../pages/store/_productItem.vue' /* webpackChunkName: "pages/store/_productItem" */))
const _70ab861e = () => interopDefault(import('../pages/_dynamicPage.vue' /* webpackChunkName: "pages/_dynamicPage" */))
const _8db64f52 = () => interopDefault(import('@/pages/_dynamicPage.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _b4e7604a,
    children: [{
      path: "",
      component: _a4d373c4,
      name: "account"
    }, {
      path: "addresses",
      component: _8a569224,
      name: "account-addresses"
    }, {
      path: "favorites",
      component: _d5c979ba,
      name: "account-favorites"
    }, {
      path: "history",
      component: _fd715b3a,
      name: "account-history"
    }, {
      path: "loyalty",
      component: _4a402adc,
      name: "account-loyalty"
    }, {
      path: "preferences",
      component: _2ebde244,
      name: "account-preferences"
    }, {
      path: "profile",
      component: _cad57316,
      name: "account-profile"
    }, {
      path: "history/:historyItem",
      component: _244dcc87,
      name: "account-history-historyItem"
    }]
  }, {
    path: "/cart",
    component: _30c598e5,
    name: "cart"
  }, {
    path: "/confirm-registration",
    component: _8ccc3f30,
    name: "confirm-registration"
  }, {
    path: "/confirm-subscribe",
    component: _e4cc5eaa,
    name: "confirm-subscribe"
  }, {
    path: "/confirmation",
    component: _2162b877,
    name: "confirmation"
  }, {
    path: "/delivery",
    component: _13941494,
    name: "delivery"
  }, {
    path: "/favorites",
    component: _998c04f6,
    name: "favorites"
  }, {
    path: "/help",
    component: _5b2c1074,
    name: "help"
  }, {
    path: "/login",
    component: _0b88c44c,
    name: "login"
  }, {
    path: "/order-tracker",
    component: _5e592ae7,
    name: "order-tracker"
  }, {
    path: "/order-view",
    component: _212bf5f4,
    name: "order-view"
  }, {
    path: "/payment",
    component: _161b6334,
    name: "payment"
  }, {
    path: "/registration",
    component: _6399513e,
    name: "registration"
  }, {
    path: "/reset-password",
    component: _01ef7dca,
    name: "reset-password"
  }, {
    path: "/search-empty",
    component: _51b2bbaa,
    name: "search-empty"
  }, {
    path: "/size-guide",
    component: _7e18cc75,
    name: "size-guide"
  }, {
    path: "/size-guide-old",
    component: _b4b32c62,
    name: "size-guide-old"
  }, {
    path: "/storefinder",
    component: _5d601c16,
    name: "storefinder"
  }, {
    path: "/thank-you-page",
    component: _20beaf76,
    name: "thank-you-page"
  }, {
    path: "/time-out",
    component: _428935e0,
    name: "time-out"
  }, {
    path: "/store/search",
    component: _1187d258,
    name: "store-search"
  }, {
    path: "/size-guide-old/:sizeGuidePageId?",
    component: _59770839,
    name: "size-guide-old-sizeGuidePageId"
  }, {
    path: "/size-guide/:sizeGuidePageId?",
    component: _2444a1d3,
    name: "size-guide-sizeGuidePageId"
  }, {
    path: "/store/:index",
    component: _9a0029fa,
    name: "store"
  }, {
    path: "/store/:productItem",
    component: _00747013,
    name: "store-productItem"
  }, {
    path: "/:dynamicPage?",
    component: _70ab861e,
    name: "dynamicPage"
  }, {
    path: "/:pathMatch(.*)*",
    component: _8db64f52
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
