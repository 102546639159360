export default {
    title: 'Личный кабинет',
    readMore: 'Узнать больше',
    welcomeText: 'С возвращением',
    welcomeDelivery: 'Рады снова тебя видеть - давай уточним все детали доставки...',

    // DETAILS
    titleDetails: 'Мои данные',
    accountDetails: 'Личные данные',
    profile: {
        title: 'Мои данные',
        checkDetails: 'Пожалуйста, проверь указанные данные',
        data: 'Данные',
        infoTitle: 'Данные для входа в аккаунт',
        changeProfile: 'Изменить профиль',
        removeAccount: 'Удалить аккаунт',
        removeInfo: 'После удаления аккаунта ты больше не сможешь быть участником adidas Universe.'
    },

    // HISTORY
    titleHistory: 'История заказов',
    history: {
        title: 'История заказов',
        findAnother: 'Ищешь другой заказ?',
        findOrder: 'Отследить заказ',
        yourOrder: 'Твой заказ',
        showDetail: 'Смотреть детали заказа',
        loadMore: 'Загрузить еще',

        expectedDateLabel: 'Ожидаемая дата доставки',
        details: 'Детали',
        order: 'Заказ',
        payment: 'Оплата',
        deliveryType: 'Метод доставки',
        address: 'Адрес',
        delivery: 'Доставка',
        discount: 'Скидка',
        summaryLabel: 'Общая сумма',
    },

    // ADDRESSES
    titleAddresses: 'Адреса доставки',
    savedAddresses: 'Сохраненные адреса',

    addresses: {
        title: 'Адреса',
        newBtn: 'Новый адрес',
        emptyList: 'У тебя пока нет сохраненных адресов',
        changeBtn: 'Изменить адрес',
        removeBtn: 'Удалить',
        byDefault: 'Адрес по умолчанию',
        titleAdd: 'Добавить адрес',
        courierDelivery: 'Курьерская доставка',
        Kyiv: 'Киeв',
        Khreshchatyk: 'ул. Крещатик',
        enterPhoneInfo: 'Введи актуальный номер мобильного телефона. На этот номер ты получишь информацию о статусе своего заказа',
        saveBtn: 'Сохранить',
        cancelBtn: 'Отменить',
    },

    // LOYALTY
    titleLoyalty: 'Клубная программа',

    // preferences page
    preferences: {
        title: 'Предпочтения',
        subscribe: 'Подписаться',
        subscribeTitle: 'Рассылка adidas',
        subscribeText: 'Подпишись на рассылку adidas, чтобы первым (ой) узнавать об акциях, лимитированных коллекциях, специальных предложениях, мероприятиях и конкурсах. Ниже ты можешь выбрать, о чем тебе было бы интересно узнавать.',
        subscribeRadio: 'Я согласен на обработку моих персональных данных, включая трансграничную передачу и передачу третьим лицам, уполномоченным ДП «Адідас-Україна» для осуществления целей маркетинга, рекламы и изучения мнений группой компаний adidas. Я прочитал Политику Конфиденциальности и согласен с ее положениями. Я понимаю, что могу отозвать свое согласие, следуя по специальной ссылке в сообщениях от adidas. Я согласен получать персонализированные маркетинговые сообщения по электронной почте от ООО "Адидас" и runtastic GmbH ( "runtastic"), а также для adidas и runtastic для обработки моих персональных данных с целью получения и просмотра таких сообщений.',
        youSubscribed: 'Ты подписан на нашу рассылку',
        unsubscribe: '* Чтобы отписаться, убери галочку в соответствующем поле',
        interestedTitle: 'Выбери то, что тебе интересно',
        interestedText: 'Чтобы получать только нужную информацию, выбери какие направления тебе нравятся.',
        updateInterested: 'Обнови список предпочтений',
    },

    // FAVORITES
    addToFavorite: 'Добавить в избранное',
    favorite: 'Избранное',
    favoriteList: 'Список желаний',
    titleFavorites: 'Избранное',
    favoriteModel: 'Избранные модели',
    favoriteTotal: 'Понравившиеся модели',
    favoriteEmpty: 'Список избранных моделей пуст. Начни делать покупки и добавляй понравившиеся модели в избранное.',

    //RegisterBanner
    registerTitle: 'Следи за списком понравившихся моделей',
    registerText: 'Вступай в adidas universe и получи скидку 10% на первый заказ. Или войди в свой аккаунт и сохрани понравившуюся(шиеся) модель(ли).',
    registerLoginQuestion: 'Уже являешься участником adidas universe?',

    welcomeHead: {
        hi: 'Привет',
        not: 'Не',
        logout: 'Выйти',
    },
    cartWidget: {
        inCart: 'В корзине',
        totalProducts: 'Всего товаров',
        goToCart: 'Перейти в корзину',
    },
    order: {
        dateOrder: 'Дата заказа',
        showDetail: 'Смотреть детали заказа',
    },
    coupons: {
        title: 'Твои купоны',
        infoExpired: 'Промокод можно активировать только один раз. Баллы действительны в течение 1 года.',
        isCopy: 'Промокод скопирован',
        showPromoCode: 'Показать промокод',
        hidePromoCode: 'Спрятать промокод',
        emptyList: 'Нет дополнительных промокодов.',
        onlyForWeb: 'Купон действует только на сайте adidas.ua',
        onlyForMobile: 'Купон действует только в приложении',
    },
    universe: {
        title: 'adidas universe баллы и скидки',
        description: 'ADIDAS UNIVERSE - это программа лояльности с прогрессивной скидкой до 20% на продукцию adidas в фирменных магазинах и дисконт-центрах adidas, а также на сайте adidas.ua.',
        expired: 'Ваша скидка действует еще',
        days: 'дня (дней)',
        points: 'баллов',
        currentLevel: 'Текущий уровень',
        accumulate1: 'Накопи еще',
        accumulate2: 'баллов',
        accumulate2_1: 'до',
        accumulate3: 'и достигни следующего уровня скидки!',
        accumulate3_1: '1 балл - 1 гривна. Узнай больше о',
    },

    removePopup: {
        titleEnd: 'мы будем скучать',
        info1: 'Ты уверен, что хочешь удалить учетную запись adidas? У тебя больше не будет доступа к личной информации в аккаунте: истории заказов, художественному альбому и статистике прогресса.',
        info2: 'Если ты решишь удалить свой аккаунт, письмо с подтверждением удаления аккаунта будет отправлено на',
        removeBtn: 'Удалить аккаунт',
        cancel: 'Отменить'
    },

    changeData: {
        title: 'Редактировать данные',
        saveBtn: 'Обновить данные',
        cancelBtn: 'Отменить',

    },

    changeEmail: {
        title: 'Редактировать Email',
        saveBtn: 'Сохранить изменения',
        cancelBtn: 'Отменить',
    },

    changePassword: {
        title: 'Изменить пароль',
        saveBtn: 'Сохранить изменения',
        cancelBtn: 'Отменить',
        oldLabel: 'Старый пароль',
        newLabel: 'Новый пароль',
        errorLength: 'Пароль должен содержать не менее 8 символов'
    },

    successRegister: {
        title: 'Остался один клик',
        text: 'Письмо с ссылкой подтверждения подписки\n' +
            'отправлено на почту',
        btn: 'ОК',
    },
}
