export default {
    title: 'Корзина',
    toCheckout: 'Оформить',
    product: 'товар',
    products: 'товаров',
    product_n: 'товара',

    cartInfo: '• Бесплатная доставка заказов на сумму от 2500 грн<br>' +
        '• Доставка доступна по всей Украине службой "Новая пошта"',
    cartInfoTemp: '• В период с 05.12 до 06.12 доставка бесплатная.<br>' +
        '• Доставка доступна по всей Украине службой "Новая пошта"',

    timeOut: {
        title: 'Время сессии',
        title2: 'исчерпано',
        subTitle: 'Что это означает?',
        text: 'В целях обеспечения онлайн-безопасности и защиты личных данных сессия браузера прекращается после 20 минут отсутствия активности. Но время на сайте не прошло зря. Мы сохранили все выбранные товары в корзине.',
        button: 'Вернуться в корзину'
    },

    popup: {
        here: 'здесь',
        forLink: 'ссылке',
        delivery: {
            title: 'Доставка',
            text_01: 'Мы отправляем товары по всей Украине. Исключение составляют временно оккупированные территории в Донецкой и Луганской областях, а также Крым.',
            text_02: 'Доставка заказов по Украине на сумму от 2500 грн осуществляется бесплатно. Если сумма заказа составляет менее 2500 грн, стоимость доставки рассчитывается на этапе оформления заказа на сайте и составляет 79 грн для доставки в отделение и 99 грн для курьерской доставки по адресу.<br>' +
                'Обрати внимание: если вариант оплаты указан наличными при получении, то тебе будет необходимо осуществить дополнительный платеж за перевод денежных средств:<br>' +
                '"Нова Пошта" — 20 грн + 2% от суммы заказа.',
            chapter_01: {
                title: 'Доставка в отделение “Нова пошта”',
                b1: 'Доставка осуществляется до суток в пределах Киева и 2 дней по Украине.',
                b2: 'Отследить заказ: ' ,
            },
            chapter_02: {
                title: 'Доставка у відділення "Justin"',
                b1: 'Доставка осуществляется в течение 1-2 дней в пределах Киева и 2-3 дня по Украине.',
                b2: 'Отследить заказ: ' ,
            },
            chapter_03: {
                title: 'Доставка у відділення "УкрПошта"',
                b1: 'Доставка осуществляется в течение 2-3 дней в пределах Киева и 3-5 дней по Украине.',
                b2: 'Отследить заказ: ' ,
            },
            chapter_04: {
                title: 'Доставка в почтомат “Нова пошта”',
                text: 'На данный момент доставка на почтомат, доступна через курьерскую службу «Нова Пошта». Все, что нужно иметь с собой — это мобильный телефон с установленным приложением «Нова Пошта». Почтоматы работают круглосуточно. После прибытия посылки в почтомат, поступит уведомление о прибытии. Оплатить заказ (наложенный платеж и услуги доставки) можно в мобильном приложении «Нова Пошта».' ,
            },
            chapter_05: {
                title: 'Курьерская доставка по адресу',
                text_01: 'Курьерская доставка по адресу возможна, если сумма заказа составляет менее 14999 грн. Если сумма заказа составляет более 14999 грн рекомендуем доставить заказ в отделение службы доставки или оплатить заказ картой на сайте при оформлении.' ,
                text_02: 'Обрати внимание: срок хранения заказа на стороне курьерской компании ограничен, если за 5 дней не будет согласована доставка, товар будет возвращен на наш склад, заказ при этом отменяется.' ,
                text_03: 'Подробнее об условиях доставки ты можешь узнать по ' ,
            },
        },
        return: {
            title: 'Возврат',
            text_01: 'Можно вернуть товар, который по каким-либо причинам не подошел, при условии сохранения его товарного вида, упаковки, этикеток, товарной накладной и желательно наличие чека (но не обязательно). Именно поэтому мы советуем примерить и внимательно осмотреть товар после его получения в комфортной обстановке, не срывая этикеток. На возврат товара есть 60 дней с момента его покупки.',
            text_02: 'Исключение составляют группы товаров, не подлежащих возврату/обмену согласно ЗУ. Со списком товаров, которые не подлежат возврату, ты можешь ознакомиться по ',
            chapter: {
                title: 'ВОЗВРАТ ЗАКАЗОВ ИЗ ИНТЕРНЕТ-МАГАЗИНА ADIDAS ПО УКРАИНЕ',
                b1: 'Можно частично или полностью отказаться от заказа при доставке курьером, если товар не подойдет (не применимо при бесконтактной доставке).',
                b2: 'Заказы, доставленные на дом, на отделение службы доставки и в почтомат, можно вернуть сейчас на центральный склад adidas:<br>' +
                    '- через отделение “Нова пошта”.',
            },
            allText: 'Процесс оформления возврата на центральный склад описан по ссылке.'
        },
        contacts: {
            title: 'Контакты',
            supportTitle: 'Служба поддержки клиентов',
            supportSchedule: 'Время работы: с 9:00 до 21:00, без выходных:',
            supportB1: 'Номер телефона: 0 800 21 22 32',
            supportB2: 'Чат на сайте adidas.ua, иконка чата доступна на каждой странице сайта.',
            supportB3: 'Отправить сообщение можно через диалоговое окно чата или выбрать удобный тебе мессенджер (Telegram или Viber).',
            supportCancel: 'Для того, чтобы отменить заказ нажми',
            chapter_01: {
                title: 'Адреса и контакты магазинов',
                text: 'Информация о розничных магазинах доступна по',
            },
            chapter_02: {
                title: 'adidas Group',
                text: 'Более подробная информация о деятельности компании на сайте adidas Group<br>' +
                    'ДП "Адидас-Украина" 03067, г. Киев, ул. Гарматная, 4.',
            },
        }
    },

    sidebar: {
        help: {
            title: 'Нужна помощь?',
            delivery: 'Доставка',
            return: 'Возврат',
            contacts: 'Контакты',
        },
        pay: {
            title: 'Методы оплаты'
        }
    },

    empty: {
        title: 'Корзина пуста',
        text: 'Здесь появятся товары добавленные в корзину. Начать покупки?',
        toStore: 'За покупками',
        tooltip: 'В корзине нет товаров'
    },

    content: {
        total: 'Всего',
        size: 'Размер',
        sizeEnded: 'Этот размер скоро закончится',
        sizeEnd: 'Нет в наличии',
        count: 'Количество',
        yourOrder: 'Твой заказ',
        firstPrice: 'Начальная цена',
        sale: 'Скидка',
        withVat: 'включая НДС',
        promocode: 'Промо-код',
        noIdentify: 'не идентифицирован. Проверь, правильный ли промо-код, и попробуй еще раз.',
        apply: 'Применить',
        code: 'Код',
        applied: 'Применено',
        delivery: 'Доставка',
        enterPromoCode: 'Введи промо-код',
        maxCountTitle: 'Пожалуйста, измени свой заказ ',
        maxCountText: 'Количество товаров в корзине превышает максимальное значение 10.',
    },

    notification: {
        updateCartTitle: {
            title: 'Список добавленых в корзину товаров был обновлен.',
            text: ' {name} добавлено в избранное.'
        }
    }
}
